/* eslint-disable */
import xssEncode from 'xss'
// import schttp from 'public/src/services/schttp'
import { setCookie, getCookie } from '@shein/common-function'

!(function () {
  /* 营销代码跟踪 */
  // !(function () {
  //   var href = document.location.href
  //   var orid = getLocalStorage('originOtherId') || '' // null，之前是undefined;
    

  //   if (!orid && (href.indexOf('url_from') > -1 || href.indexOf('utm_source') > -1)) {
  //     schttp({
  //       url: '/api/common/source/update',
  //       params: {
  //         urlcurrent: encodeURIComponent(href)
  //       }
  //     }).then(function (data) {
  //       if (data.code == 0 && data.info != null) {
  //         setLocalStorage({ key: 'originOtherId', value: data.info, expire: 30 * 24 * 3600 * 1000 })
  //         if (typeof sa === 'function') {
  //           sa('send', {
  //             activity_name: 'expose_origin_otherid',
  //             activity_param: {}
  //           })
  //         }
  //       }
  //     })
  //   }
  // }())

  if (navigator.cookieEnabled) {
    var cookieId = getCookie('cookieId')
    if (!cookieId) {
      cookieId = window.$System.Math.IntUtil.genGUIDV4()
      // 根据逻辑：词出的true为curMainDomain 

      setCookie({ key: 'cookieId', value: cookieId, end: 400 * 24 * 60 * 60, curMainDomain: true })
    }
    const imgctn = document.createElement('div')
    imgctn.id = 'imgcontainer'
    imgctn.style.display = 'none'
    imgctn.innerHTML = '<img src="' + xssEncode(gbCommonInfo.langPath) + '/api/common/image/cookieId/get?cookieid=' + xssEncode(cookieId) + '" style="display:none;">'
    document.body.appendChild(imgctn)
  }
}())
